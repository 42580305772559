import React from "react"
import { graphql } from "gatsby"
import BlogCategoryIndex from '../templates/blogCategoryIndex'

export default function Blog({ data, location }) {
  return (<BlogCategoryIndex data={data.allWordpressPost} location={location} />)
}


export const pageQuery = graphql`
  query {
    allWordpressPost(
      sort: {
      fields: [date]
      order: DESC
      }
    ) {
      edges {
        node {
          title
          excerpt
          slug
          fimg_url {
            source_url
          }
          featured_media {
            localFile {
              childImageSharp {
                fluid(quality: 90, maxWidth: 1000){
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    file(relativePath: { eq: "images/fallbackImage.jpeg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920){
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`